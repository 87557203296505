<template>
    <div
        style="border-right: 1px solid #ccc; border-left: 1px solid #ccc"
        class="px-4"
    >
        <h4 class="mb-2 text-uppercase">Application Details</h4>
        <h4 class="mb-2">
            <v-icon class="mr-2 primary--text text--disabled"
                >mdi-receipt</v-icon
            >
            <span
                class="primary--text text--lighten-1 text-uppercase font-weight-regular"
                >Order #:</span
            >
            <span class="font-weight-bold text-uppercase">
                {{ applicant.order_id }}</span
            >
        </h4>
        <h4 class="mb-2">
            <v-icon class="mr-2 primary--text text--disabled"
                >mdi-arrow-right-bold-box</v-icon
            >
            <span
                class="primary--text text--lighten-1 text-uppercase font-weight-regular"
                >Source:</span
            >
            <span class="font-weight-bold text-uppercase">
                {{ applicant.application.source }}</span
            >
        </h4>
        <v-divider class="mt-4"></v-divider>
        <h4 class="mt-4 mb-2 text-uppercase">Products</h4>
        <ul style="list-style: none; padding-left: 10px">
            <template v-for="(product, index) in products">
                <li :key="index">
                    <span
                        class="text-uppercase font-weight-light text-subtitle-2 text-truncate d-inline-block"
                        style="max-width: 240px"
                        >{{ product.name }}</span
                    >
                </li>
            </template>
        </ul>
    </div>
</template>
<script>
export default {
    name: "applicant-profile-application",
    components: {},
    props: {
        applicant: {
            type: Object,
            required: true,
        },
        products: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>