var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "px-4",
      staticStyle: {
        "border-right": "1px solid #ccc",
        "border-left": "1px solid #ccc"
      }
    },
    [
      _c("h4", { staticClass: "mb-2 text-uppercase" }, [
        _vm._v("Application Details")
      ]),
      _c(
        "h4",
        { staticClass: "mb-2" },
        [
          _c("v-icon", { staticClass: "mr-2 primary--text text--disabled" }, [
            _vm._v("mdi-receipt")
          ]),
          _c(
            "span",
            {
              staticClass:
                "primary--text text--lighten-1 text-uppercase font-weight-regular"
            },
            [_vm._v("Order #:")]
          ),
          _c("span", { staticClass: "font-weight-bold text-uppercase" }, [
            _vm._v(" " + _vm._s(_vm.applicant.order_id))
          ])
        ],
        1
      ),
      _c(
        "h4",
        { staticClass: "mb-2" },
        [
          _c("v-icon", { staticClass: "mr-2 primary--text text--disabled" }, [
            _vm._v("mdi-arrow-right-bold-box")
          ]),
          _c(
            "span",
            {
              staticClass:
                "primary--text text--lighten-1 text-uppercase font-weight-regular"
            },
            [_vm._v("Source:")]
          ),
          _c("span", { staticClass: "font-weight-bold text-uppercase" }, [
            _vm._v(" " + _vm._s(_vm.applicant.application.source))
          ])
        ],
        1
      ),
      _c("v-divider", { staticClass: "mt-4" }),
      _c("h4", { staticClass: "mt-4 mb-2 text-uppercase" }, [
        _vm._v("Products")
      ]),
      _c(
        "ul",
        { staticStyle: { "list-style": "none", "padding-left": "10px" } },
        [
          _vm._l(_vm.products, function(product, index) {
            return [
              _c("li", { key: index }, [
                _c(
                  "span",
                  {
                    staticClass:
                      "text-uppercase font-weight-light text-subtitle-2 text-truncate d-inline-block",
                    staticStyle: { "max-width": "240px" }
                  },
                  [_vm._v(_vm._s(product.name))]
                )
              ])
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }